import {useGame} from "~/store/gameStore";

export default defineNuxtRouteMiddleware((to, from) => {
    if (to.name === 'slot-name-id') {
        if (useGame().isRepair) {
            useGame().showAlertRepair()
            console.log('from', from)
            return from.fullPath
        }
    }
    return;
})
